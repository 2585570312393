import React from "react"
import styles from "../styles/headingNotes.module.scss"
import { motion } from "framer-motion"

const getText = page => {
  if (page === "dynamicMaps") {
    return "Check out some of the data visualizations / dynamic maps that I've built using: "
  }

  if (page === "staticMaps") {
    return "Take a look at a selection of my most popular static maps / visualizations built with: "
  }
}

const getTech = page => {
  if (page === "dynamicMaps") {
    return ["R", "D3", "Ggiraph", "Plotly"]
  }

  if (page === "staticMaps") {
    return ["R", "ggplot2"]
  }
}

export default function HeadingNotes({ page }) {
  const text = getText(page)
  const tech = getTech(page)

  return (
    <motion.div
      initial={{
        scale: 0.75,
        opacity: 0,
        transition: {
          duration: 0.5,
        },
      }}
      animate={{
        scale: 1,
        opacity: 1,
        transition: {
          duration: 0.5,
        },
      }}
      className={styles.headingNotes}
    >
      <div className={styles.text}>{text}</div>
      <div className={styles.techContainer}>
        {tech.map(item => {
          return <span className={styles.tech}>{item}</span>
        })}
      </div>
    </motion.div>
  )
}
