const data = [
  {
    imageUrl: "https://i.imgur.com/JiXhGUq.jpg",
    title: "The 1931 Yugoslav Census Data",
    pageUrl: "census1931/index.html",
  },
  {
    imageUrl: "https://i.imgur.com/JiXhGUq.jpg",
    title: "Jugoslavija prema popisu iz 1931.",
    pageUrl: "census1931srb/index.html",
  },
  {
    imageUrl: "https://imgur.com/Ca9BlK5.jpg",
    title: "COVID 19 Cases in Italy",
    pageUrl: "covid19italy/covid19ita.html",
  },
  {
    imageUrl: "https://imgur.com/iQbN209.jpg",
    title: "COVID 19 Cases in the Netherlands",
    pageUrl: "covid19netherlands/covid19nld.html",
  },
  {
    imageUrl: "https://imgur.com/5xZR7Df.jpg",
    title: "COVID-19 Cases in Slovenia",
    pageUrl: "covid19svn/covid19svn.html",
  },
  {
    imageUrl: "https://i.imgur.com/lAN76nB.jpg",
    title: "Protests in Serbia",
    pageUrl: "protests/index.html",
  },
  {
    imageUrl: "https://i.imgur.com/0aAl0rC.jpg",
    title: "Car Accidents in Belgrade in 2018",
    pageUrl: "belgrade/index.html",
  },
  {
    imageUrl: "https://i.imgur.com/UGyRLjd.png",
    title: "Violence in Kosovo (1998-2000)",
    pageUrl: "kosovo/index.html",
  },
  {
    imageUrl: "https://i.imgur.com/Tvfx61N.png",
    title: "The Illiteracy Rate in the Kingdom of Serbia in 1895",
    pageUrl: "serbia1895illiteracy/index.html",
  },
  {
    imageUrl: "https://i.imgur.com/3K56btZ.png",
    title: "Mass Concealed Graves in Slovenia after 1944",
    pageUrl: "slovenia/index.html",
  },
  {
    imageUrl: "https://i.imgur.com/JUNNzNG.jpg",
    title: "Total Yugoslav Casualties in World War II (1941-1945)",
    pageUrl: "ww2/index.html",
  },
]

export default data
