import React from "react"
import styles from "../styles/maps.module.scss"
import ContentCard from "./contentCard"
import data from "../data/dynamicMaps"
import HeadingNotes from "./HeadingNotes"

export default function DynamicMaps({ rootPath }) {
  return (
    <div className={styles.maps}>
      <HeadingNotes page="dynamicMaps" />
      <div className={styles.mapItems}>
        {data.map((node, index) => {
          const slug = `${rootPath}/visualizations/${node.pageUrl}`

          return (
            <ContentCard
              key={index}
              slug={slug}
              image={node.imageUrl}
              buttonText="View Visualization"
              buttonUrl={slug}
              isInternal={false}
              type={"interactive"}
              title={node.title}
              index={index}
            />
          )
        })}
      </div>
    </div>
  )
}
