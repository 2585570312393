import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DynamicMaps from "../components/DynamicMaps"

export default function Visualizations(props) {
  const { location } = props
  return (
    <Layout>
      <SEO
        title="Dynamic Maps and Data Visualizations"
        pagePath={location.pathname}
      />
      <DynamicMaps rootPath={location.origin} />
    </Layout>
  )
}
